import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import Table from '../Table/Table';
import HistoryModal from './HistoryModal';
import MWModal from './MWModal';
import strings from '../../Helpers/StringsHelpers';
import { setMaintenanceWarning, deleteMaintenanceWarning } from '../Api/Api';

export default function NCModal({ show, handleClose, systemMaintenance, warningList, setShow }){

    const { register, handleSubmit, reset, formState: { errors }, getValues, setValue } = useForm();
    const [ hShow, setHShow ] = useState(false);
    const [ mwShow, setMWShow ] = useState(false);
    const [ mwId, setMWId ] = useState();

    const columns = [
        {
            title: 'ID',
            data: 'maintenance_warning_id',
            className: 'text-center align-middle',
        },
        {
            title: 'Descrição',
            data: 'maintenance_warning_text',
            className: 'align-middle',
            width: '50%',
        },
        {
            title: 'Operador',
            data: 'user_name',
            className: 'text-center align-middle',
        },
        {
            title: 'Encarregado',
            data: 'maintenance_warning_contact',
            className: 'text-center align-middle',
        },
        {
            title: 'Data',
            data: 'maintenance_warning_date',
            className: 'text-center align-middle',
          },
        {
            title: 'Ações',
            data: null,
            className: 'text-center align-middle'
        }
    ];

    const actionButtons = (row) => {
        return (
            <>
                <button className='btn btn-sm btn-success px-2 m-2' title='Editar' onClick={()=>editMW(row.maintenance_warning_id)}><i className='bi bi-pencil-fill'></i></button>
                <button className='btn btn-sm btn-danger px-2' title='Excluir' onClick={()=>deleteMW(row.maintenance_warning_id)}><i className="bi bi-trash"></i></button>
            </>
        )
    }

    const save = async (data, flag = 0) => {
        data.systemMaintenanceId = systemMaintenance.system_maintenance_id;
        data.userId = sessionStorage.getItem("gestao_userId");

        Swal.fire({
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();

                let postData = {
                    maintenanceWarningText: data.maintenanceWarningText,
                    maintenanceWarningContact: data.maintenanceWarningContact,
                    systemMaintenanceId: data.systemMaintenanceId,
                    userId: data.userId
                }

                const res = await setMaintenanceWarning(postData);

                if (res.data.data == "success") {
                    Swal.fire({
                        title: 'Tratativa incluída com sucesso!',
                        icon: 'success',
                        allowOutsideClick: false,
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setShow(false);
                            hide();
                        }
                    })
                } else {
                    Swal.fire("OPS!", strings.OCORREU_UM_ERRO_AO_TENTAR_PROCESSAR_ESSA_REQUISICAO, "error");
                }
            }
        })
    }

    const deleteMW = async (id) => {
        Swal.fire({
            title: 'Exclusão de tratativa',
            text: 'Deseja mesmo excluir essa tratativa?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
          }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    didOpen: async () => {
                        Swal.showLoading();

                        let postData = {
                            maintenance_warning_id: id,
                            user_id: sessionStorage.getItem("gestao_userId")
                        }

                        const res = await deleteMaintenanceWarning(postData);

                        if (res.data.data == "success") {
                            Swal.fire({
                                title: 'Tratativa excluída com sucesso!',
                                icon: 'success',
                                allowOutsideClick: false,
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'Ok'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    setShow(false);
                                    hide();
                                }
                            })
                        } else {
                            Swal.fire("OPS!", strings.OCORREU_UM_ERRO_AO_TENTAR_PROCESSAR_ESSA_REQUISICAO, "error");
                        }
                    }
                })
            }
        })
    }

    const editMW = (mw_id) => {
        setMWShow(true);
        setMWId(mw_id)
    }

    const hide = () => {
        resetForm();
        handleClose();
    }

    const handleHShow = () => {
        setHShow(true);
    }

    const handleHClose = () => {      
        setHShow(false);
    }

    const handleMWClose = () => {      
        setMWShow(false);
    }

    const resetForm = () => {
        const { maintenanceWarningText, maintenanceWarningContact } = getValues();
        
        if (maintenanceWarningText || maintenanceWarningContact) {
            sessionStorage
            .setItem("gestao_ncModalForm", JSON.stringify({ 
                maintenanceWarningText: maintenanceWarningText, 
                maintenanceWarningContact: maintenanceWarningContact
            }));
        }

        reset({
            maintenanceWarningText: '',
            maintenanceWarningContact: ''
        });
    }

    useEffect(()=>{
        const formValues = JSON.parse(sessionStorage.getItem("gestao_ncModalForm"));
        if (formValues) {
            setValue("maintenanceWarningText", formValues.maintenanceWarningText);
            setValue("maintenanceWarningContact", formValues.maintenanceWarningContact);
        }
    }, [systemMaintenance, setValue]);

    return (
        <>
            <Modal size="lg" show={show} onHide={hide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Tratativas com o cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card subtitle">
                        <div className="card-body d-flex align-items-center justify-content-between">
                            <label className="text-muted">
                                <b>NC {systemMaintenance.system_maintenance_id}</b> - {systemMaintenance.system_maintenance_text}
                            </label>
                            <button type="button" className="btn btn-outline-secondary"  title="Infos NC" onClick={() => handleHShow()}><i className="bi bi-eye-fill"></i></button>
                        </div>
                    </div>
                    <br/>
                    <div className="card card_table border-0">
                        <div className="card-body">
                            <Table data={Array.isArray(warningList) ? warningList : []} columns={columns} btnColumn={5} actBtns={actionButtons} />
                        </div>
                    </div>
                    <hr/>
                    <form id="form" onSubmit={handleSubmit(save)} noValidate>
                        <div className='col-12 mb-3'>
                            <div className="form-floating">
                                <textarea className="form-control" id="descRport" placeholder="Descrição" aria-label='Descrição' {... register('maintenanceWarningText',{required:true})}></textarea>
                                <label htmlFor="descRport">Descrição</label>
                            </div>
                            {errors.maintenanceWarningText?.type === 'required' && <div className='text-danger'><small>Informe a descrição</small></div>}
                        </div>
                        <div className='col-12 mb-3'>
                            <div className="form-floating">
                                <input className="form-control" id="encarregado" placeholder="Nome do encarregado" aria-label='Nome do Encarregado' {... register('maintenanceWarningContact',{required:true})}></input>
                                <label htmlFor="encarregado">Nome do encarregado</label>
                            </div>
                            {errors.maintenanceWarningContact?.type === 'required' && <div className='text-danger'><small>Informe o nome do encarregado</small></div>}
                        </div>
                        <div className='col-12'>
                            <button type="submit" className='btn btn-success float-end'>Adicionar</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <HistoryModal hShow={hShow} handleHClose={handleHClose} systemMaintenance={systemMaintenance}/>
            <MWModal mwShow={mwShow} handleMWClose={handleMWClose} maintenanceWarningId={mwId} systemMaintenanceId={systemMaintenance.system_maintenance_id} handleCloseNC={handleClose}/>
        </>
    )
}