import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import strings from '../../Helpers/StringsHelpers'
import { getWarningList,setMaintenanceWarning } from '../Api/Api';

export default function MWModal({mwShow, handleMWClose, maintenanceWarningId, systemMaintenanceId, handleCloseNC}){

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    const onSubmit = async (data) => {
        Swal.fire({
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();

                let postData = {
                    maintenanceWarningText: data.maintenanceWarningText,
                    maintenanceWarningContact: data.maintenanceWarningContact,
                    maintenanceWarningId: maintenanceWarningId,
                    userId: data.userId,
                    systemMaintenanceId: systemMaintenanceId
                }

                const res = await setMaintenanceWarning(postData);

                if (res.data.data == "success") {
                    Swal.fire({
                        title: 'Tratativa editada com sucesso!',
                        icon: 'success',
                        allowOutsideClick: false,
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            handleMWClose();
                            handleCloseNC();
                        }
                    })
                } else {
                    Swal.fire("OPS!", strings.OCORREU_UM_ERRO_AO_TENTAR_PROCESSAR_ESSA_REQUISICAO, "error");
                }
            }
        })
    }

    const loadMW = async (systemMaintenanceId) => {
        const res = await getWarningList(systemMaintenanceId);

        if (res && res.data) {
            const selectedWarning = res.data.find(
                (warning) => warning.maintenance_warning_id == maintenanceWarningId
            );

            setValue('maintenanceWarningText', selectedWarning? selectedWarning.maintenance_warning_text : '');
            setValue('maintenanceWarningContact', selectedWarning? selectedWarning.maintenance_warning_contact : '');
            setValue('operator', selectedWarning? selectedWarning.user_name : '');
            setValue('created', selectedWarning? selectedWarning.maintenance_warning_date : '');
            setValue('userId', selectedWarning? selectedWarning.user_id : '');
        }
    }

    useEffect(() => {
        if (mwShow && systemMaintenanceId) {
            loadMW(systemMaintenanceId);
        }
    }, [mwShow, systemMaintenanceId]);

    return (
        <>
            <Modal size="lg" show={mwShow} onHide={handleMWClose} style={{ backgroundColor: "#212121a6" }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edição de tratativa (ID - {maintenanceWarningId})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='row mb-3'>
                            <div className='col-2'>
                                <div className="form-floating">
                                    <input className="form-control" id="identify" aria-label='ID' value={maintenanceWarningId} readOnly></input>
                                    <label htmlFor="identify">ID</label>
                                </div>
                            </div>
                            <div className='col-2'>
                                <div className="form-floating">
                                    <input className="form-control" id="nc" aria-label='NC' value={systemMaintenanceId} readOnly></input>
                                    <label htmlFor="nc">NC</label>
                                </div>
                            </div>
                            <div className='col-5'>
                                <div className="form-floating">
                                    <input className="form-control" id="operator" aria-label='Operador' {... register('operator')} readOnly></input>
                                    <label htmlFor="operator">Operador</label>
                                </div>
                            </div>
                            <div className='col-3'>
                                <div className="form-floating">
                                    <input className="form-control" id="created" aria-label='Data' {... register('created')} readOnly></input>
                                    <label htmlFor="created">Data</label>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-12'>
                                <div className="form-floating">
                                    <textarea className="form-control" id="descRport" placeholder="Descrição" aria-label='Descrição' {... register('maintenanceWarningText',{required:true})}></textarea>
                                    <label htmlFor="descRport">Descrição</label>
                                </div>
                                {errors.maintenanceWarningText?.type === 'required' && <div className='text-danger'><small>{strings.CAMPO_OBRIGATORIO}</small></div>}
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-12'>
                                <div className="form-floating">
                                    <input className="form-control" id="encarregado" placeholder="Nome do encarregado" aria-label='Nome do Encarregado' {... register('maintenanceWarningContact',{required:true})}></input>
                                    <label htmlFor="encarregado">Nome do encarregado</label>
                                </div>
                                {errors.maintenanceWarningContact?.type === 'required' && <div className='text-danger'><small>{strings.CAMPO_OBRIGATORIO}</small></div>}
                            </div>
                        </div>
                        <div className="row d-none">
                            <input className="form-control" placeholder="User ID" aria-label='User ID' {... register('userId')} readOnly></input>
                        </div>
                        <div className="row">
                            <div className='col'>
                                <button type="submit" className="btn btn-success float-end px-4">Editar</button>
                            </div>
                        </div>
                </form>
                </Modal.Body>
            </Modal>
        </>
    )
}