import React from 'react';
import Swal from 'sweetalert2';
import 'bootstrap-icons/font/bootstrap-icons.css';

import '../../themes/styles.css';

export default function Header(props) {

  function closeSidebar() {
    document.getElementById('sidebar').classList.toggle('fechar');
    document.getElementById('header').classList.toggle('abrir');
  }

  window.onresize = function () {
    var w = document.documentElement.clientWidth;
    if (w < '900') {
      document.getElementById('sidebar').classList.add('fechar');
      document.getElementById('header').classList.add('abrir');
    }
  }
  
  const handleSignOut = () => {
    Swal.fire({
      title: 'Está de saída?',
      text: "Confirme sua saída do sistema",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
            sessionStorage.removeItem('gestao_userId');
            window.location.href = props.base_url;
          }
        })
      }
    })
  }

  const goBack = async () => {
      window.history.back();
  }

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <li className="nav-item d-block">
        <a className="nav-link nav-icon toggle-sidebar-btn" onClick={closeSidebar} href="#">
          <i className="bi bi-list"></i>
        </a>
      </li>
      <li className='nav-item d-none d-md-block'>
        <div className='d-flex justify-content-center'>
          <div className='logoSvg'></div>
        </div>
      </li>
      <li className='nav-item d-none d-md-block nav-item-shop'>
        <div className='d-flex justify-content-center'>
          <div hidden className='logoSvgUnidadeAdmin'></div>
        </div>
      </li>
      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          <li className="nav-item d-block d-md-none">
            <div className='d-flex justify-content-center me-3'>
              <div className='logoSvg'></div>
            </div>
          </li>
          <li>
            <a className="nav-link nav-icon toggle-sidebar-btn" onClick={goBack} href="#" title="Voltar">
                <i className="bi bi-chevron-left"></i>
            </a>
          </li>
          <li className="nav-item dropdown pe-3">
            <a className="nav-link nav-icon toggle-sidebar-btn" onClick={handleSignOut} href="#" title="Sair">
                <i className="bi bi-box-arrow-right"></i>
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}