import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Chart } from 'react-google-charts';
import { useForm } from 'react-hook-form';
import html2canvas from 'html2canvas';
import moment from 'moment';
import jsPDF from 'jspdf';
import whiteLogoSafety from '../../images/logoBranco.png';
import { getQuantTratativasOp, getSupervisaoMaintenance } from '../Api/Api';

export default function SupervisionModal({show, handleClose, initialInfo = {}, optionsPie = {}, initialDataPie = [["Operador", "Quantidade de tratativas"]]}) {

    const { register, handleSubmit } = useForm();
    const [info, setInfo] = useState({});
    const [dataPie, setDataPie] = useState([]);

    const isObjectEmpty = (obj) => Object.keys(obj).length === 0;

    const exportPDF = () => {
        const input = document.getElementById('modalSupervision');
            
        html2canvas(input, { scale: 1 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
    
            const imgWidth = canvas.width * 0.264583;
            const imgHeight = canvas.height * 0.264583;
    
            const pdf = new jsPDF({
                orientation: imgWidth > imgHeight ? 'landscape' : 'portrait',
                unit: 'mm',
                format: [imgWidth, imgHeight],
            });
    
            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    
            const logoImg = new Image();
            logoImg.src = whiteLogoSafety;
    
            logoImg.onload = function () {
                const logoWidth = (logoImg.width * 0.264583) / 3;
                const logoHeight = (logoImg.height * 0.264583) / 3;
    
                const overlayXPosition = 5;
                const overlayYPosition = 4;
    
                pdf.addImage(logoImg, 'PNG', overlayXPosition, overlayYPosition, logoWidth, logoHeight);
    
                pdf.save(`Painel de Supervisão - Relatório gerencial.pdf`);
            };
    
            logoImg.onerror = function () {
                console.error('Erro ao carregar a imagem.');
            };
        });
    }

    const filter = async (data) => {
        let postData = {
            dt_ini: data.dt_init,
            dt_fim: data.dt_end
        }

        const res = await getSupervisaoMaintenance(postData);
        if (res.data) {
            setInfo(res.data);
        }

        const res1 = await getQuantTratativasOp(postData);
        if (res1.data) {
            const newDataPie = res1.data.map(element => [
              element.operator_name,
              Number(element.treatment_count)
            ]);
            setDataPie([["Operador", "Quantidade de tratativas"], ...newDataPie]);
        }
    }

    const getDisplayValue = (key) => {
        return !isObjectEmpty(info) ? info[key] : initialInfo[key];
    }

    const getDisplayValueChart = () => {
        return !isObjectEmpty(dataPie) ? dataPie : initialDataPie;
    }

    return (
        <Modal show={show} size="xl" onHide={handleClose}>
            <div id="modalSupervision">
                <Modal.Header className="text-center noGradient bg-red-default" closeButton>
                    <Modal.Title className="w-100">
                        <span>Painel de Supervisão - Relatório gerencial</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <form onSubmit={handleSubmit(filter)}>
                        <div className="row">
                            <div className="col-md-5 col-sm-12">
                                <label>Data início</label>
                                <input type="date" max={moment().startOf('day').format("YYYY-MM-DD")} className="form-control" {...register('dt_init')} required/>
                            </div>
                            <div className="col-md-5 col-sm-12">
                                <label>Data fim</label>
                                <input type="date" className="form-control" {...register('dt_end')} required/>
                            </div>
                            <div className="col-md-2 col-sm-12 align-self-end">
                                <button className="btn py-1 btn-secondary btnFilter w-100" type="submit">Filtrar</button>
                            </div>
                        </div>
                    </form>
                    <hr className="my-4"/>
                    <div className="row d-flex justify-content-around">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                            <div className="card noShadow">
                                <div className="card-body bg-NCs-day space">
                                    <div className="card-title text-center">
                                        <i className="bi bi-calendar-week-fill icon-supervision"></i>
                                        <h2 className="mt-4"><b>{getDisplayValue('tratadas_todas')}</b></h2>
                                        <h5><b>Tratativas</b></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3">
                            <div className="card noShadow">
                                <div className="card-body bg-NCs-day space">
                                    <div className="card-title text-center">
                                        <i className="bi bi-calendar-range-fill icon-supervision"></i>
                                        <h2 className="mt-4"><b>{getDisplayValue('tratadas')}</b></h2>
                                        <h5><b>NCs tratadas</b></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3">
                            <div className="card noShadow">
                                <div className="card-body bg-NCs-day space">
                                    <div className="card-title text-center">
                                        <i className="bi bi-calendar2-check-fill icon-supervision"></i>
                                        <h2 className="mt-4"><b>{getDisplayValue('encerradas')}</b></h2>
                                        <h5><b>NCs encerradas</b></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-sm-12 col-md-12 col-lg-12 scrollAxis'>
                            <div className="chartMobile">
                                <Chart
                                    chartType="PieChart"
                                    data={getDisplayValueChart()}
                                    options={{
                                        ...optionsPie,
                                        legend: { position: "labeled" },
                                      }}
                                    width={"100%"}
                                    height={"400px"}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </div>
            <Modal.Footer>
                <Button variant="btn btn-success" onClick={exportPDF}>
                    Exportar para PDF
                </Button>
            </Modal.Footer>
        </Modal>
    )
}